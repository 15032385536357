<template>
  <div class="container-top">
    <div class="text-center" style="margin-top:80px" v-if="vError">
      <h2>[카드오류]</h2>
      <div style="margin-top:8px">{{ errMsg }}</div>
      <button class="button is-primary body4-medium" style="margin-top:24px;width:120px" @click="confirm">확인</button>
    </div>
    <div class="text-center" style="margin-top:80px"  v-if="vOk">
      <div>카드정보가 정상 등록되었습니다.</div>
      <button class="button is-primary body4-medium" style="margin-top:24px;width:120px" @click="confirm">확인</button>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  import sha256 from 'js-sha256';

  export default {
    name: "billReg",
    created() {
      if(this.$route.query.prev) {
        this.vError = true;
        let result = JSON.parse(this.$route.query.result);
        this.errorMsg(result);
      }
      else if(this.$route.query.result) {

        let result = JSON.parse(this.$route.query.result);
        if (result.resultCode !== '00' && result.resultCode !== '0000') {
          this.vError = true;
        } else {
          let basket = this.$store.getters.basket;
          if(basket.buyername) {
            result.buyername = basket.buyername;
          } else {
            result.buyername = basket.name;
          }
          if(basket.buyertel) {
            result.buyertel = basket.buyertel;
          } else {
            result.buyertel = basket.phone;
          }
          if(basket.buyeremail) {
            result.buyeremail = basket.buyeremail;
          } else {
            result.buyeremail = basket.email;
          }

          this.$store.commit('setLoading', true);
          this.$axios.post('public/launchpack/service/reg_bill', result).then(res=>{
            if(res.status === 200) {
              this.vOk = true;
              this.$store.commit('setLoading', false);
            }
          }).catch(()=>{
            this.vError = true;
            this.$store.commit('setLoading', false);
          });
        }
      } else {
        this.setBill();
      }
    },
    data() {
      return {
        vOk: false,
        vError: false,
        requests: {
          merchantkey: 'UTVHRTRPaEV4YUFYNTBrWGFDZW9hdz09',
          mid: 'launchpack',
          // merchantkey: 'b09LVzhuTGZVaEY1WmJoQnZzdXpRdz09',
          // mid: 'INIBillTst',
          buyername: '이남호',
          goodname: '런치팩 솔루션 월 요금제',
          price: '580000',
          orderid: '',
          returnurl: '',
          merchantreserved: '',
          timestamp: '',
          period: 'M2',
          period_custom: '',
          hashdata: ''
        },
        errMsg: '입력하신 카드정보가 잘 못 되었습니다.'
      }
    },
    methods: {
      confirm() {
        if(this.$store.getters.prevPath === '') {
          this.$router.replace('/home');
        } else {
          let path = this.$store.getters.prevPath;
          this.$store.commit('setPrevPath', '');
          this.$router.replace(path);
        }
      },
      errorMsg(result) {
        let errMsg = '입력하신 카드정보가 잘 못 되었습니다.';
        if(result.resultmsg) {
          let result_msg = result.resultmsg;
          if (result_msg.indexOf('1165')>-1 || result_msg.indexOf('1223')>-1) {
            errMsg = '카드 유효기간이 잘 못 되었습니다.';
          } else if(result_msg.indexOf('1214')>-1) {
            errMsg = '카드번호가 잘 못 되었습니다.';
          } else if(result_msg.indexOf('1219')>-1) {
            errMsg = '카드 비밀번호가 잘 못 되었습니다.';
          } else if(result_msg.indexOf('1184')> -1) {
            errMsg = '법인 사업자 번호가 잘 못 되었습니다.';
          } else if(result_msg.indexOf('1251')> -1) {
            errMsg = '주민번호가 잘 못 되었습니다.';
          }
          this.errMsg = errMsg;
        }
      },
      setBill() {
        let query = this.$route.query;
        /*
          name, phone, email, goodname, price, option_type=1
         */
        let pg = 'https://plugin.launchpack.co.kr';

        this.requests.buyername = query.name;
        this.requests.buyertel = query.phone;
        this.requests.buyeremail = query.email;
        this.requests.goodname = query.goodname;
        this.requests.price = query.price;

        let p_noti = {
          buyername: query.name,
          buyertel: query.phone,
          buyeremail: query.email,
          goodname: query.goodname,
          price: query.price
        };
        this.requests.p_noti = JSON.stringify(p_noti);
        this.requests.returnurl = pg+'/php/inicis/inicis/pay/lpMobilBillKeyReturn.php';
        this.requests.period = query.option_type === 1 ? 'M2' : '';

        if(this.$route.query.type!=='order') {
          this.$store.commit('setBasket', this.requests);
        }
        let form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', 'https://inilite.inicis.com/inibill/inibill_card.jsp');

        let orderid = `launchpack_${ moment().format('YYYYMMDDHHmmss') + moment().milliseconds() }`;
        let timestamp = moment().format('YYYYMMDDHHmmss');

        let keys = Object.keys(this.requests);
        for(let i=0; i<keys.length; i++) {
          let key = keys[i];
          if(key === 'orderid') {
            this.input(form, key, orderid);
          }
          if(key === 'timestamp') {
            this.input(form, key, timestamp);
          }
          else if(key === 'hashdata') {
            let hashdata = sha256.sha256(this.requests.mid + orderid + timestamp + this.requests.merchantkey);
            this.input(form, key, hashdata);
          }
          else
            this.input(form, key, this.requests[key]);
        }

        document.body.appendChild(form);
        form.submit();
      },
      input(form, name, value) {
        let input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', name);
        input.setAttribute('value', value);
        form.appendChild(input);
      }
    }
  }
</script>
